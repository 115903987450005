import * as Yup from "yup";

import { Formik, Form as FormikForm } from "formik";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AuthController from "@controllers/AuthController";
import { AxiosError } from "axios";
import Button from "@components/Button";
import EnterCode from "./Login/shared/EnterCode";
import FormError from "@components/FormError";
import FormHeader from "@components/FormHeader";
import FormLinks from "@components/FormLinks";
import FormView from "@components/FormView";
import HttpProvider from "@api/HttpProvider";
import TextField from "@components/TextField";
import { createToast } from "@helpers/createToast";
import isEmpty from "lodash/isEmpty";
import useAppDispatch from "@hooks/useAppDispatch";
import { useIubenda } from "@hooks/useIubenda";

const initialValues = {
  email: "",
  password: "",
};

interface ILoginForm {
  email: string;
  password: string;
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const api = new HttpProvider();

const TraditionalLogin = ({
  traditional,
  enterCode,
}: {
  traditional?: boolean;
  enterCode?: boolean;
}) => {
  useIubenda();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorNotification, setErrorNotification] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const onError = () => {
    setIsLoading(false);
    setErrorNotification(true);
  };

  const saveToken = (key: string, value: string) =>
    localStorage.setItem(key, value);

  const onSuccess = (data: any) => {
    setIsLoading(false);
    saveToken("access_token", data.access_token);
    saveToken("refresh_token", data.refresh_token);

    const origin = localStorage.getItem("origin");

    if (origin === "manage") {
      localStorage.removeItem("origin");
      window.location.href = `${process.env.REACT_APP_BACK_BASE_URL}auth/login-from-platform?access_token=${data.access_token}`;
      //  dispatch(setLoader(true));
    } else {
      navigate("/dashboard", { replace: true });
    }
  };

  const onSuccessWithout2FA = (data: any, params: any) => {
    setIsLoading(false);
    saveToken("access_token", data.access_token);
    saveToken("refresh_token", data.refresh_token);
    navigate("create-2fa", { replace: true, state: params });
  };

  const onSuccessWithoutPhoneNumberInDB = (
    data: any,
    params: any,
    username: string,
  ) => {
    setIsLoading(false);
    saveToken("access_token", data.access_token);
    saveToken("refresh_token", data.refresh_token);
    navigate("create-2fa/code", {
      replace: true,
      state: {
        phone: params.phone,
        username,
      },
    });
  };

  const onSubmit = async (values: ILoginForm) => {
    setIsLoading(true);
    setErrorNotification(false);
    localStorage.removeItem("has_phone_number");
    if (traditional) {
      AuthController.traditionalAuth(onSuccess, onError, values);
    } else {
      const params = {
        username: values.email,
        password: values.password,
      };

      let sendCodeResponse: any;

      try {
        // send code
        sendCodeResponse = await api.request(
          "post",
          "api/auth/phone/verification",
          params,
        );
      } catch (error) {
        if ((error as AxiosError)?.response?.status === 429) {
          // if code has been sent less than 2 minutes ago
          createToast(
            "You've requested a code recently, please wait 2 minutes to try again.",
            "warning",
            dispatch,
          );
        }
        setIsLoading(false);
        setErrorNotification(true);
      }

      if (sendCodeResponse?.status === 200) {
        // input code
        navigate("enter-code", {
          state: {
            username: values.email,
            password: values.password,
            phone: sendCodeResponse?.data.data.phone,
          },
        });
      }

      if (sendCodeResponse?.status === 202) {
        AuthController.auth(
          (res: any) =>
            onSuccessWithoutPhoneNumberInDB(
              res,
              sendCodeResponse.data.data,
              values.email,
            ),
          onError,
          values,
        );
      }

      if (sendCodeResponse?.status === 204) {
        // login
        AuthController.auth(onSuccess, onError, values);
        return;
      }

      if (sendCodeResponse?.status === 205) {
        localStorage.setItem("has_phone_number", "false");
        AuthController.authForExternalsWithoutTwoFA(
          onSuccessWithout2FA,
          onError,
          values,
        );
      }
    }
  };

  const validateFormHasEmptyFields = (values: ILoginForm) => {
    const { email, password } = values;
    if (!isEmpty(email) && !isEmpty(password)) return false;
    return true;
  };

  useEffect(() => {
    if (searchParams.get("access_token")) {
      localStorage.setItem("access_token", searchParams.get("access_token")!);
      navigate("/dashboard");
    }

    if (
      process.env.REACT_APP_AUTH_PROVIDER &&
      process.env.REACT_APP_ENABLE_LOGIN_ONBOARDING === "false"
    ) {
      navigate(`/login/${process.env.REACT_APP_AUTH_PROVIDER}`);
    }
  }, [navigate, searchParams]);

  if (enterCode) {
    // puede ser un state
    return <EnterCode />;
  }

  return (
    <FormView
      renderHeader={
        <FormHeader title="Welcome" subtitle="Enter your Credentials" />
      }
      renderFooter={
        <FormLinks
          link="/request-access"
          hideLink={process.env.REACT_APP_REDUCED_ONBOARDING === "true"}
        />
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={LoginSchema}
      >
        {({ errors, handleBlur, values }) => (
          <FormikForm
            className="c-login__form-container d-flex flex-column flex-grow-1"
            noValidate
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <TextField
              name="email"
              label="E-mail"
              error={errors.email}
              type="email"
              placeholder="Enter E-mail"
              handleBlur={handleBlur}
              errorHandler={errorNotification}
              iconName="email"
            />
            <TextField
              name="password"
              label="Password"
              error={errors.password}
              type="password"
              placeholder="Enter password"
              handleBlur={handleBlur}
              errorHandler={errorNotification}
              renderError={
                errorNotification ? (
                  <FormError error="Invalid Credentials" />
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <></>
                )
              }
              iconName="lock"
            />
            <div className="bottom-button-container d-flex flex-grow-1 justify-content-center align-items-end">
              <Button
                type="submit"
                isLoading={isLoading}
                size="big"
                typeStyle="block"
                value="Login"
                disabled={
                  validateFormHasEmptyFields(values) || !isEmpty(errors)
                }
              />
            </div>
          </FormikForm>
        )}
      </Formik>
    </FormView>
  );
};
export default TraditionalLogin;
