/* eslint-disable no-nested-ternary */
import React from "react";
import _ from "lodash";
import TextLink from "@components/TextLink";
import { useLocation } from "react-router-dom";

interface IFormLinksProps {
  link?: string;
  hideLink?: boolean;
}

const FormLinks = ({ hideLink, link }: IFormLinksProps) => {
  const location = useLocation();

  const showForgotPassword =
    process.env.REACT_APP_REDUCED_ONBOARDING === "true" || !hideLink;
  const showRequestAccess =
    link &&
    (_.isEqual(location.pathname, `/login`) ||
      process.env.REACT_APP_REDUCED_ONBOARDING === "true");

  return (
    <div className="c-form-links o-ft-300 text-center">
      {showForgotPassword && (
        <TextLink
          text="Forgot password?"
          type="route"
          route="/forgot-password"
        />
      )}

      {showRequestAccess && (
        <div className="d-flex flex-row justify-content-center">
          <span className="c-form-links__bottom-label o-ft-lg-400">
            Need Access?
          </span>
          <TextLink text="Request it here" type="href" route={link} />
        </div>
      )}
      <div>
        <a
          href="https://www.iubenda.com/privacy-policy/16434398"
          className="iubenda-embed iubenda-nostyle c-form-links__link o-ft-lg-400"
          title="Privacy Policy"
        >
          Privacy Policy
        </a>
        <span className="c-form-links__separator o-ft-xl"> | </span>
        <a
          href="https://www.iubenda.com/terms-and-conditions/16434398"
          className="iubenda-embed iubenda-nostyle c-form-links__link o-ft-lg-400"
          title="Terms and Conditions"
        >
          Terms and Conditions
        </a>
      </div>
    </div>
  );
};

export default FormLinks;
